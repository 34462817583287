import { createContext, useContext, useReducer } from "react";

import layoutReducer from "reducers/layoutReducer";

const LayoutContext = createContext(null);
const LayoutDispatchContext = createContext(null);

export const useLayout = () => {
    return useContext(LayoutContext);
};

export const useLayoutDispatch = () => {
    return useContext(LayoutDispatchContext);
};

const LayoutProvider = ({ children }) => {
    const [layout, dispatch] = useReducer(layoutReducer, {
        gridCount: 0,
        gridPage: 1,
    });

    return (
        <LayoutContext.Provider value={layout}>
            <LayoutDispatchContext.Provider value={dispatch}>
                {children}
            </LayoutDispatchContext.Provider>
        </LayoutContext.Provider>
    );
};

export default LayoutProvider;
