import { Box } from "@mui/material";

import DesktopNav from "components/DesktopNav";
import MobileNav from "components/MobileNav";
import useBreakpoint from "hooks/useBreakpoint";
import useScrollThreshold from "hooks/useScrollThreshold";

const Nav = ({ parPx, parPy, ...props }) => {
    const links = [
        { label: "Início", to: "/" },
        { label: "Ícones", to: "/type?q=icon" },
        { label: "Banners", to: "/type?q=banner" },
        { label: "Ilustrações", to: "/type?q=illustration" },
        { label: "Sites", to: "/type?q=site" },
        { label: "Contato", to: "https://linktr.ee/byseidr" },
    ];
    parPx = useBreakpoint(parPx);
    parPy = useBreakpoint(parPy);
    const threshold = useScrollThreshold({ maxValue: 50, type: "pixel" });

    return (
        <Box
            position={threshold ? "fixed" : "absolute"}
            zIndex={20}
            width={(theme) => `calc(100% - 2 * ${theme.spacing(parPx)})`}
            my={(theme) =>
                threshold ? `calc(${theme.spacing(parPy)} * -1)` : 0
            }
            px={{ xs: 2, md: 4 }}
            py={1.5}
            fontFamily="'Open Sans', sans-serif"
            {...props}
        >
            <DesktopNav display={{ xs: "none", lg: "flex" }} links={links} />
            <MobileNav display={{ xs: "flex", lg: "none" }} links={links} />
        </Box>
    );
};

export default Nav;
