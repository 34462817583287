import { styled } from "@mui/material/styles";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import logoBlack from "images/logo-black.png";
import logoLight from "images/logo-light.png";

const Link = styled((props) => <RouterLink {...props} />)({
    display: "flex",
    alignItems: "center",
});

const Logo = ({ size, variant, link = "/", textDisabled, ...props } = {}) => {
    let logo;
    switch (variant) {
        case "black":
            logo = logoBlack;
            break;
        case "light":
        default:
            logo = logoLight;
    }

    return (
        <Box {...props}>
            <Link to={link}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar
                        alt="By Seidr"
                        src={logo}
                        sx={{
                            height: "100%",
                            "& img": {
                                height: size,
                                objectFit: "contain",
                            },
                        }}
                    />
                    {textDisabled || <Typography>BY SEIDR</Typography>}
                </Stack>
            </Link>
        </Box>
    );
};

export default Logo;
