import Grid from "components/Grid";
import usePosts from "hooks/usePosts";

const Posts = ({ query, enableStrictType = false }) => {
    const items = usePosts(query, enableStrictType);

    return <Grid items={items} />;
};

export default Posts;
