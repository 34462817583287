import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Logo from "components/Logo";

const MobileNav = ({ links = [], ...props } = {}) => {
    const [page, setPage] = useState(links?.[0]?.label);

    const handleChange = (event) => {
        setPage(event.target.value);
    };

    return (
        <Stack
            position="relative"
            zIndex={10}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
            color="white"
            {...props}
        >
            <Logo size={70} />
            <Box width={0.4}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Página
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={page}
                        label="Página"
                        onChange={handleChange}
                    >
                        {links.map(({ label, to }, i) => (
                            <MenuItem key={i} value={label}>
                                <Link to={to}>{label}</Link>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Stack>
    );
};

export default MobileNav;
