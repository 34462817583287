import { useSearchParams } from "react-router-dom";

import Posts from "components/Posts";

const Search = () => {
    const [searchParams] = useSearchParams();
    const query = searchParams.get("q") ?? "";

    return <Posts query={query} />;
};

export default Search;
