import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";

import { db } from "../firebase";
import { simplify } from "helpers/simplify";
import getImageTypes from "helpers/getImageTypes";

const usePosts = (query, isStrictType = false) => {
    const [posts, setPosts] = useState([]);
    query = simplify(query?.toLowerCase());

    useEffect(() => {
        (async () => {
            await getDocs(collection(db, "posts")).then((querySnapshot) => {
                let postDocs = querySnapshot.docs;
                postDocs = postDocs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }));

                if (query) {
                    postDocs = postDocs.filter((post) => {
                        const title = simplify(post.title?.toLowerCase());
                        const tags = post.tags?.map((tag) =>
                            simplify(tag?.toLowerCase())
                        );
                        const types = getImageTypes(post.images);
                        return (
                            title?.includes?.(query) ||
                            tags?.some?.((tag) => tag?.includes?.(query)) ||
                            types?.some?.((type) => query?.includes?.(type))
                        );
                    });
                }

                if (isStrictType) {
                    postDocs = postDocs.map((post) => {
                        post.images = post.images.filter((image) => {
                            let type = getImageTypes(image)?.[0] ?? query;
                            return type === query;
                        });
                        return post;
                    });
                }

                setPosts(postDocs);
            });
        })();
    }, [query, isStrictType]);

    return posts;
};

export default usePosts;
