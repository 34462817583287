import { Box, Container, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";

import Footer from "components/Footer";
import Header from "components/Header";
import LayoutProvider from "providers/LayoutProvider";
import Nav from "components/Nav";

const Layout = () => {
    const px = { xs: 0, md: 15 };
    const py = { xs: 0, md: 7 };

    return (
        <LayoutProvider>
            <Box minHeight="100vh" px={px} py={py} bgcolor="#10100F">
                <Nav parPx={px} parPy={py} />
                <Header />
                <Stack spacing={5}>
                    <Container maxWidth="lg" sx={{ mt: -12 }}>
                        <Outlet />
                    </Container>
                    <Footer />
                </Stack>
            </Box>
        </LayoutProvider>
    );
};

export default Layout;
