import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";

import { getPageSlice } from "helpers/getPageSlice";
import { getPageSum } from "helpers/getPageSum";
import { useLayout, useLayoutDispatch } from "providers/LayoutProvider";
import GridItem from "components/GridItem";
import getImageSrcs from "helpers/getImageSrcs";
import useBreakpoint from "hooks/useBreakpoint";

const Grid = ({ items }) => {
    const pagesAmount = 35;
    const pageAmount = items.length < pagesAmount ? items.length : pagesAmount;
    const [loaded, setLoaded] = useState(0);
    const threshold = useBreakpoint({ xs: 0.2, md: 0.7 });
    const { ref, inView } = useInView({
        threshold,
        skip: !items.length || loaded !== pageAmount,
    });
    const { gridPage } = useLayout();
    const cols = useBreakpoint({ xs: 2, sm: 4, md: 6 });
    const dispatch = useLayoutDispatch();
    const gap = useBreakpoint({ xs: 15, md: 12 });
    const pageSlice = getPageSlice(gridPage, pagesAmount);

    useEffect(() => {
        dispatch({ type: "SETGRIDPAGE", payload: 1 });
        if (!items?.length) return;
        dispatch({
            type: "SETGRIDCOUNT",
            payload: getPageSum(items.length, pagesAmount),
        });
    }, [dispatch, items]);

    const handleLoad = (event) => {
        setLoaded((prevState) => prevState + 1);
    };

    return (
        <Box width={1}>
            <ImageList
                variant="masonry"
                cols={cols}
                gap={gap}
                sx={{
                    img: {
                        filter: `grayscale(${inView ? 0 : 100}%) brightness(${
                            inView ? 100 : 43
                        }%) contrast(${inView ? 100 : 250}%)`,
                        transition: "all 0.5s",
                    },
                }}
                ref={ref}
            >
                {items
                    .slice(...pageSlice)
                    .map(({ images, title, description, tags }, i) => (
                        <GridItem
                            key={i}
                            images={getImageSrcs(images)}
                            title={title}
                            description={description}
                            tags={tags}
                            handleLoad={handleLoad}
                        />
                    ))}
            </ImageList>
        </Box>
    );
};

export default Grid;
