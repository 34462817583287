const Media = ({ variant = "image", src, alt, poster } = {}) => {
    const style = { width: "100%", height: "100%", objectFit: "cover" };

    switch (variant) {
        case "video":
            return (
                <video autoPlay loop muted poster={poster} style={style}>
                    {src?.webm && <source src={src.webm} type="video/webm" />}
                    {src?.mp4 && <source src={src.mp4} type="video/mp4" />}
                </video>
            );
        case "image":
        default:
            return <img src={src} alt={alt} style={style} />;
    }
};

export default Media;
