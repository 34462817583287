import { Pagination, PaginationItem, Stack } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";

import { useLayout, useLayoutDispatch } from "providers/LayoutProvider";
import footerIcon from "styles/footerIcon";
import navigateExternal from "helpers/navigateExternal";
import DiscordIcon from "icons/DiscordIcon";
import Logo from "components/Logo";
import ShareButton from "components/ShareButton";
import SocialButton from "components/SocialButton";

const Footer = (props) => {
    const dispatch = useLayoutDispatch();
    const { gridCount } = useLayout();
    const { gridPage } = useLayout();
    const iconColor = "red";
    const iconSize = 15;

    const handleDiscord = () => {
        navigateExternal("https://discord.com/users/925496145348800612");
    };

    const handleEmail = () => {
        navigateExternal("mailto://contato@byseidr.com");
    };

    const handleInstagram = () => {
        navigateExternal("https://instagram.com/ojohnmuniz");
    };

    const handleGitHub = () => {
        navigateExternal("https://github.com/byseidr");
    };

    const handlePagination = (event, page) => {
        dispatch({ type: "SETGRIDPAGE", payload: page });
    };

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            height="5vh"
            color="#fff"
        >
            <Stack direction="row" spacing={1}>
                <Pagination
                    count={gridCount}
                    page={gridPage}
                    variant="outlined"
                    onChange={handlePagination}
                    siblingCount={0}
                    hidePrevButton
                    hideNextButton
                    renderItem={(item) => (
                        <PaginationItem
                            sx={{
                                ...footerIcon(iconColor, iconSize),
                                fontWeight: "bold",
                            }}
                            {...item}
                        />
                    )}
                />
            </Stack>
            <Logo
                size={65}
                textDisabled
                sx={{
                    opacity: 0.4,
                    transition: "all 0.2s",
                    ":hover": { opacity: 0.7 },
                }}
            />
            <Stack direction="row" spacing={1}>
                <SocialButton
                    size={iconSize}
                    color={iconColor}
                    Icon={DiscordIcon}
                    onClick={handleDiscord}
                />
                <SocialButton
                    size={iconSize}
                    color={iconColor}
                    Icon={InstagramIcon}
                    onClick={handleInstagram}
                />
                <SocialButton
                    size={iconSize}
                    color={iconColor}
                    Icon={EmailIcon}
                    onClick={handleEmail}
                />
                <SocialButton
                    size={iconSize}
                    color={iconColor}
                    Icon={GitHubIcon}
                    onClick={handleGitHub}
                />
                <ShareButton size={iconSize} color={iconColor} />
            </Stack>
        </Stack>
    );
};

export default Footer;
