import polkaBg from "styles/polkaBg";
import AbsoluteBox from "components/AbsoluteBox";
import Media from "components/Media";

const Background = ({
    variant = "image",
    src,
    alt,
    poster,
    colorOverlayDisabled,
    gradientOverlayDisabled,
    polkaOverlayDisabled,
    ...props
} = {}) => {
    return (
        <AbsoluteBox {...props}>
            <Media variant={variant} src={src} alt={alt} poster={poster} />
            {colorOverlayDisabled || (
                <AbsoluteBox sx={{ background: "#000", opacity: "0.15" }} />
            )}
            {polkaOverlayDisabled || <AbsoluteBox sx={polkaBg(5)} />}
            {gradientOverlayDisabled || (
                <AbsoluteBox
                    sx={{
                        background:
                            "linear-gradient(180deg, rgb(0 0 0 / 0%) 60%, rgb(0 0 0 / 75%) 100%)",
                    }}
                />
            )}
        </AbsoluteBox>
    );
};

export default Background;
