import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton,
    VKIcon,
    VKShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import { Popover, Stack } from "@mui/material";
import React, { useState } from "react";
import ShareIcon from "@mui/icons-material/Share";

import SocialButton from "../SocialButton";

const ShareButton = ({ size, color }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const url = "https://byseidr.com";

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <SocialButton
                size={size}
                color={color}
                Icon={ShareIcon}
                onClick={handleClick}
            />
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                slotProps={{
                    paper: {
                        sx: {
                            bgcolor: "transparent",
                            backgroundImage: "none",
                            boxShadow: "none",
                        },
                    },
                }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        px: 2,
                        py: 1,
                        circle: {
                            fill: color,
                            transition: "all 0.5s",
                            ":hover": { fill: "#fff" },
                        },
                        path: { fill: "#000", pointerEvents: "none" },
                    }}
                >
                    <EmailShareButton url={url}>
                        <EmailIcon size={size + 8 * 2} round />
                    </EmailShareButton>
                    <FacebookShareButton url={url}>
                        <FacebookIcon size={size + 8 * 2} round />
                    </FacebookShareButton>
                    <LinkedinShareButton url={url}>
                        <LinkedinIcon size={size + 8 * 2} round />
                    </LinkedinShareButton>
                    <PinterestShareButton url={url}>
                        <PinterestIcon size={size + 8 * 2} round />
                    </PinterestShareButton>
                    <RedditShareButton url={url}>
                        <RedditIcon size={size + 8 * 2} round />
                    </RedditShareButton>
                    <TelegramShareButton url={url}>
                        <TelegramIcon size={size + 8 * 2} round />
                    </TelegramShareButton>
                    <TumblrShareButton url={url}>
                        <TumblrIcon size={size + 8 * 2} round />
                    </TumblrShareButton>
                    <TwitterShareButton url={url}>
                        <TwitterIcon size={size + 8 * 2} round />
                    </TwitterShareButton>
                    <VKShareButton url={url}>
                        <VKIcon size={size + 8 * 2} round />
                    </VKShareButton>
                    <WhatsappShareButton url={url}>
                        <WhatsappIcon size={size + 8 * 2} round />
                    </WhatsappShareButton>
                </Stack>
            </Popover>
        </>
    );
};

export default ShareButton;
