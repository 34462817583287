const { toArr, isObj } = require("richierich");

const getImageTypes = (images) => {
    images = toArr(images);
    images = images.map((image) => isObj(image) && image?.type);
    images = images.filter(Boolean);
    return images;
};

export default getImageTypes;
