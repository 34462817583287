const layoutReducer = (state, action) => {
    switch (action.type) {
        case "SETGRIDCOUNT":
            return { ...state, gridCount: action.payload };
        case "SETGRIDPAGE":
            return { ...state, gridPage: action.payload };
        default:
            return state;
    }
};

export default layoutReducer;
