import { Box } from "@mui/material";

import bg from "images/header-bg.gif";
import figure from "images/header-figure.png";
import Background from "components/Background";
import useScrollDistance from "hooks/useScrollDistance";

const Header = (props) => {
    const distance1 = useScrollDistance({ maxValue: 150, type: "pixel" });
    const distance2 = useScrollDistance({ maxValue: 300, type: "pixel" });

    return (
        <Box
            position="relative"
            height={{ xs: "60vh", md: "60vh" }}
            sx={{ filter: "drop-shadow(0 0 10px rgb(0 0 0 / 50%))" }}
        >
            <Background
                src={bg}
                alt="Header background"
                height={{ xs: 0.55, md: 1 }}
                sx={{
                    filter: `grayscale(${distance1}%)`,
                    opacity: `${100 - distance2}%`,
                    transition: "all 0.5s",
                    clipPath: "polygon(0 0, 100% 0, 100% 85%, 0% 100%)",
                }}
                colorOverlayDisabled
                polkaOverlayDisabled
            />
            <Box
                position="absolute"
                left="50%"
                top="50%"
                height={{ xs: 0.4, md: 0.51 }}
                sx={{
                    transform: "translate(-50%, -63%)",
                    filter: `grayscale(${distance1}%)`,
                    opacity: `${100 - distance2}%`,
                    transition: "all 0.5s",
                }}
            >
                <img
                    src={figure}
                    alt="figure"
                    style={{
                        height: "100%",
                        filter: "drop-shadow(3px 3px 4px rgb(0 0 0 / 20%))",
                    }}
                />
            </Box>
        </Box>
    );
};

export default Header;
