import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let typingTimer;

    const inputEl = useRef();

    const handleInputChange = (event) => {
        const q = event.target.value;
        if (q) {
            if (!location.pathname.startsWith("/busca")) {
                search(q);
            } else {
                lazySearch(q);
            }
        }
    };

    const handleCloseClick = () => {
        if (inputEl.current?.value) inputEl.current.value = "";
    };

    const handleSearchClick = () => {
        if (inputEl.current?.value) search(inputEl.current.value);
    };

    const lazySearch = (q) => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(search.bind(null, q), 500);
    };

    const search = (q) => {
        navigate(`/busca?q=${q}`);
    };

    return (
        <Paper
            variant="outlined"
            component="form"
            sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 250,
                backgroundColor: "transparent",
                border: "1px solid rgba(255, 255, 255, 0.5)",
            }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Procurar"
                inputProps={{ ref: inputEl, "aria-label": "procurar" }}
                onChange={handleInputChange}
            />
            {inputEl.current?.value ? (
                <IconButton
                    type="button"
                    aria-label="search"
                    onClick={handleCloseClick}
                >
                    <CloseIcon />
                </IconButton>
            ) : (
                ""
            )}
            <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={handleSearchClick}
            >
                <SearchIcon />
            </IconButton>
        </Paper>
    );
};

export default SearchBar;
