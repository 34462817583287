import { useSearchParams } from "react-router-dom";

import Posts from "components/Posts";

const Type = () => {
    const [searchParams] = useSearchParams();
    const query = searchParams.get("q") ?? "";

    return <Posts query={query} enableStrictType />;
};

export default Type;
